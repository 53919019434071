
import { computed } from "vue";

export default {
	name: "CoreBlockDivider",
	props: {
		settings: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const blockContent = computed(() => props.settings.content || null);
		return {
			blockContent,
		};
	},
};
